import { createBrowserHistory } from "history";
import React from "react";
import ReactDOM from "react-dom";
import { Switch, Router } from "react-router-dom";

import "tippy.js/dist/tippy.css";
import "./global.css";

import Route from "components/Route";
import CustomerApp from "pages/CustomerApp";
import DriverApp from "pages/DriverApp";
import TmsApp from "pages/TmsApp";

import initializeFullStory from "./initializers/fullStory";
import initializeHoneyComb from "./initializers/honeycomb";
import initializeSentry from "./initializers/sentry";
import initializeTimeAgo from "./initializers/timeAgo";

const history = createBrowserHistory();

initializeFullStory();
initializeSentry(history);
initializeTimeAgo();
initializeHoneyComb();

ReactDOM.render(
  <React.StrictMode>
    <Router history={history}>
      <Switch>
        <Route path="/c">
          <CustomerApp />
        </Route>
        <Route path="/d">
          <DriverApp />
        </Route>
        <Route>
          <TmsApp />
        </Route>
      </Switch>
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);
