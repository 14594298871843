import { HoneycombWebSDK } from "@honeycombio/opentelemetry-web";
import { getWebAutoInstrumentations } from "@opentelemetry/auto-instrumentations-web";

export default function initializeHoneyComb(): void {
  const configDefaults = {
    ignoreNetworkEvents: true, // do not collect network timing
    propagateTraceHeaderCorsUrls: [
      /staging.api.torotms.com/g,
      /api.torotms.com/g,
      /localhost/g,
    ],
    ignoreUrls: [
      /app.launchdarkly.com/,
      /api.segment.io/,
      /cdn.pendo.io/,
      /cdn.segment.com/,
      /edge.fullstory.com/,
      /events.launchdarkly.com/,
      /google-analytics.com/,
      /google-tagmanager.com/,
      /ingest.sentry.io/,
    ],
  };

  let sdkConfig: any = {
    serviceName: "torotms-client",
    instrumentations: [
      getWebAutoInstrumentations({
        "@opentelemetry/instrumentation-xml-http-request": configDefaults,
        "@opentelemetry/instrumentation-fetch": configDefaults,
      }),
    ],
  };

  if (import.meta.env.VITE_APP_OTEL_COLLECTOR_URL) {
    sdkConfig = {
      ...sdkConfig,
      endpoint: import.meta.env.VITE_APP_OTEL_COLLECTOR_URL,
      skipOptionsValidation: true,
    };
  } else {
    sdkConfig = {
      ...sdkConfig,
      debug: true, // remove if you'd like traces sent to Honeycomb's test environment
      apiKey: import.meta.env.VITE_APP_HONEYCOMB_API_KEY,
    };
  }

  try {
    const sdk = new HoneycombWebSDK(sdkConfig);

    sdk.start();
  } catch (error) {
    console.error("Error initializing Honeycomb:", error);
  }
}
